import React, {useEffect} from "react";
import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footers/Footer";
import ConcoursForm from "../components/Forms/ConcoursForm";
// import ReactGA from "react-ga4";


export default function Concours() {

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname);
  // }, []);

  return (
    <div className="large-screen-wrapper">
      <div className="main">
        <Navbar />
        <div className="main-container-concours">
          <ConcoursForm />
        </div>
        <div className="main-footer-concours">
          <Footer />
        </div>
      </div>
    </div>
  );
}
