import React from "react";
import {FIELD_STATUS} from "./Utils";
import $ from 'jquery'; 

export default function SelectDate({label, required, name, handle, status, placeholder, description, errorMessage, type, value, ...CustomInputFieldProps}) {

  return (
    <div className="field main-text-fields">
        <label>
            {required ? (
                <h1>{label}<span className="required"> *</span><span className="description">{description}</span></h1>
              ) : (
                <h1>{label}<span className="description">{description}</span></h1>
              )
            }
        </label>

        <input className={status} type={type}  name={name} placeholder={placeholder} required pattern="\d{2}-\d{2}-\d{4}" lang="fr-CA" {...CustomInputFieldProps}/>
        {/* <input  type={type}  {...CustomInputFieldProps}/> */}


        {/* <input className={status} type={type} id="exp" name={name} placeholder={placeholder} maxlength="8" {...CustomInputFieldProps}/> */}
        {/* <input className={status} id="exp" value={value} type={type} name={name} placeholder={placeholder} maxLength="8"  onChange={handle} {...CustomInputFieldProps}/> */}
        {/* <input type="date" name="begin" 
        placeholder="dd-mm-yyyy" value=""
        min="1997-01-01" max="2030-12-31"/> */}

        {status === FIELD_STATUS.ERROR ? (<h1 className="error-message">{errorMessage}</h1>) : (<></>)}
    </div>
  );
}