
export const optionsOrigin = [
    { value : "", label: "Choisir", disable: true},
    { key: "1", label: "SPLA (UL)", value: "SPLA (UL)"},
    { key: "2", label: "Facebook", value: "Facebook"},
    { key: "3", label: "Instagram", value: "Instagram"},
    { key: "4", label: "LinkedIn", value: "LinkedIn"},
    { key: "5", label: "Portail Uqar", value: "Portail Uqar"},
    { key: "6", label: "TikTok", value: "TikTok"},
    { key: "7", label: "Quelqu'un qui travaille chez AGP", value: "Quelqu'un qui travaille chez AGP"},
    { key: "8", label: "Réseautage", value: "Réseautage"},
    { key: "9", label: "Indeed", value: "Indeed"},
];

export const optionsFormationAMFContact = [
    { value : "", label: "Choisir", disable: true},
    { key: "1", label: "J'ai complété un diplôme d'étude collégiale du Québec", value: "J'ai complété un diplôme d'étude collégiale du Québec" },
    { key: "2", label: "Je suis en voie de compléter mon diplôme d'étude collégiale du Québec", value: "Je suis en voie de compléter mon diplôme d'étude collégiale du Québec" },
    { key: "3", label: "Je réponds à un autre critère", value: "Je réponds à un autre critère" },
];

export const optionsFormationAMFConseiller = [
    { value : "", label: "Choisir", disable: true},
    { key: "1", label: "Je détiens un permis de représentant en ass. de dommage délivré par l'AMF", value: "Je détiens un permis de représentant en ass. de dommage délivré par l'AMF" },
    { key: "2", label: "Je suis en voie de détenir un permis de représentant", value: "Je suis en voie de détenir un permis de représentant" },
    { key: "3", label: "J'ai déjà détenu un permis de représentant mais je ne pratique plus", value: "J'ai déjà détenu un permis de représentant mais je ne pratique plus" },
    { key: "4", label: "Je détiens un diplôme d'étude collégial du Québec", value: "Je détiens un diplôme d'étude collégial du Québec" },
    { key: "5", label: "Je réponds à un autre critère", value: "Je réponds à un autre critère" },
];
