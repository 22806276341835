import React, { useEffect } from "react";
import { Link } from "react-scroll";

const Localisation = () => {

  useEffect(() => {
    if (window.location.href.includes("rendez-vous")) {
      document.getElementById("rendez-vous").scrollIntoView();
    }
  }, [])

  return (
    <section id="localisation">
      <div className="left-container">
        <div className="left-container-title">
          <h1>Personne</h1>
          <h1>n'aime attendre</h1>
          <h1>au téléphone.</h1>
        </div>
        <div className="left-container-links">
          <div className="left-container-links-rdv">
            <Link
              className=""
              to="rendez-vous"
              href="rendez-vous"
              smooth={true}
              duration={800}
              offset={-90}
            >
              Prenez rendez-vous
            </Link>
          </div>
          <div className="left-container-links-horaire">
            <Link
              className=""
              to="schedules"
              href="schedules"
              smooth={true}
              duration={800}
              offset={-90}
            >
              Consultez nos horaires
            </Link>
          </div>
        </div>
      </div>

      <div className="right-container">

        <div className="right-container-office">
          <div className="right-container-office-title">
            <h1>Québec</h1>
            <p>320-725 Boulevard Lebourgneuf,</p>
            <p>Québec, QC, G2J 0C4</p>
            <p style={{fontSize: "0.8em", paddingTop: "1em"}}>Même si c'est toujours un plaisir de vous voir, la meilleure façon de nous joindre reste par téléphone!</p>
          </div>
          <div className="right-container-office-link">
            <a
              href="https://www.google.com/maps/place/AGP+Assurance/@46.8428399,-71.2796948,15z/data=!4m5!3m4!1s0x0:0x67774fea4e18555!8m2!3d46.8428399!4d-71.2796948"
              onClick="https://www.google.com/maps/place/AGP+Assurance/@46.8428399,-71.2796948,15z/data=!4m5!3m4!1s0x0:0x67774fea4e18555!8m2!3d46.8428399!4d-71.2796948"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="img/marker.png"
                alt="marker map logo agp assurance"
                width="35px"
              />
            </a>
          </div>
        </div>

        <div className="right-container-office">
          <div className="right-container-office-title">
            <h1>Amqui</h1>
            <p>5-6, Rue Proulx,</p>
            <p>Amqui, QC, G5J 3G2</p>
            <p style={{fontSize: "0.8em", paddingTop: "1em"}}>Même si c'est toujours un plaisir de vous voir, la meilleure façon de nous joindre reste par téléphone!</p>

          </div>
          <div className="right-container-office-link">
            <a
              href="https://www.google.com/maps/place/AGP+Assurance/@46.8428399,-71.2796948,15z/data=!4m5!3m4!1s0x0:0x67774fea4e18555!8m2!3d46.8428399!4d-71.2796948"
              onClick="https://www.google.com/maps/place/AGP+Assurance/@46.8428399,-71.2796948,15z/data=!4m5!3m4!1s0x0:0x67774fea4e18555!8m2!3d46.8428399!4d-71.2796948"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="img/marker.png"
                alt="marker map logo agp assurance"
                width="35px"
              />
            </a>
          </div>
        </div>

        <div className="right-container-ref bottom">
          <h1>1 866-629-3686</h1>
          <a href="tel:18666293686">
            <img src="img/phone_logo.png" alt="phone logo agp assurance" width="35"/>
          </a>
        </div>

        <div className="right-container-ref">
          <h1>info@agpassurance.ca</h1>
          <a href="mailto:info@agpassurance.ca">
            <img src="img/email.png" alt="email logo agp assurance" width="35"/>
          </a>
        </div>
        {/* <a className="right-container-ref" href="tel:18666293686">
          <div>
            <a>1 866-629-3686</a>
            <img src="img/phone_logo.png" alt="phone logo agp assurance" width="35"/>
          </div>
        </a>

        <a className="right-container-ref">
          <div>
            <a>info@agpassurance.ca</a>
            <img src="img/email.png" alt="email logo agp assurance" width="35"/>
          </div>
        </a> */}
      </div>

    </section>
  );
};

export default Localisation;
