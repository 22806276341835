import React, {useEffect} from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Index from "../pages/index";
import Reclamations from "../pages/reclamations";
import Carrieres from "../pages/carrieres";
import NousJoindre from "../pages/nous-joindre";
import Particuliers from "../pages/particuliers";
import Entreprises from "../pages/entreprises";
import PolitiqueDeConfidentialite from "../pages/politique-de-confidentialite";
import Concours from "../pages/concours";
import ReactPixel from 'react-facebook-pixel';

export default function MyRouts() {

  useEffect(() => {
    ReactPixel.pageView();
  }, []);

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/particuliers" element={<Particuliers />} />
          <Route path="/entreprises" element={<Entreprises />} />
          <Route path="/reclamations" element={<Reclamations />} />
          <Route path="/carrieres" element={<Carrieres />} />
          <Route path="/nous-joindre" element={<NousJoindre />} />
          <Route path="/politique-de-confidentialite" element={<PolitiqueDeConfidentialite />} />
          <Route path="/concours" element={<Concours />} />
        </Routes>
      </Router>
    </div>
  );
}

