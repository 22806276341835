import React from "react";

const ConfirmationConcours = ({email}) => {

  return (
    <section id="confirmationconcours">

      <div className="left-confirmation">

        <div className="slogan">
          <h1>Votre participation a </h1>
          <h1>été enregistrée avec <span style={{color: "#42aaf5"}}>succès !</span></h1>
          {email? <p>Vous devriez avoir reçu une confirmation à <span>{email}</span>.</p> :<></>}
        </div>

      </div>

    </section>
  );
};

export default ConfirmationConcours;
