import {useRef, useContext} from "react";
import InputTextField from "../Fields/InputTextField"
import {SelectField} from "../Fields/SelectField"
import {useEffect, useState} from "react";
import { ApiClient } from "../../contexts/apiContext";
import { Regx, testInput } from "../Global/regx";
import {MainButton, LoadingButton} from "../Buttons/Buttons";
import {FileField} from "../Fields/FileField";
import { optionsOrigin, optionsFormationAMFContact, optionsFormationAMFConseiller } from "./Options";
import {onChange, select, FIELD_STATUS, hasExtension} from "../Fields/Utils.js"
import {ModalContext} from "../../contexts/modalContext";


export function CarrieresForm({title, handleClose, show= null, tag}){

    const {setCareerModal } = useContext(ModalContext)
    const [form, setForm] = useState();


    const [firstName, setFirstName] = useState({
        value: "",
        required: true,
        status: "",
        name: "first_name",
        placeholder: "",
        description: "",
        errorMessage: "Le prénom n'est pas valide",
        type: "text",
        isValid: (value) => testInput(value, Regx.ALPHA)
    });
    const [lastName, setLastName] = useState({
        value: "",
        required: true,
        status: "",
        name: "last_name",
        placeholder: "",
        description: "",
        errorMessage: "Le nom n'est pas valide",
        type: "text",
        isValid: (value) => testInput(value, Regx.ALPHA)
    });
    const [phone, setPhone] = useState({
        value: "",
        required: true,
        status: "",
        name: "phone",
        placeholder: "",
        description: "",
        errorMessage: "Le numéro de téléphone n'est pas valide",
        type: "tel",
        isValid: (value) => testInput(value, Regx.PHONE_NUMBER)
    });
    const [origin, setOrigin] = useState({
        value: "",
        required: true,
        name: "origin",
        type: "select",
        status:""
    });
    const [formationAMF, setFormationAMF] = useState({
        value: "",
        required: true,
        name: "formation_amf",
        type: "text",
        status: ""
    });
    const [email, setEmail] = useState({
        value: "",
        required: true,
        name: "email",
        type: "email",
        isValid: (value) => testInput(value, Regx.EMAIL)
    });
    const [attachment, setAttachment] = useState({
        value: "",
        required: true,
        name: "attachment",
        status: "",
        fileName: "",
        errorMessage: "Le fichier doit être au format pdf ou doc/docx",
        type: "file",
    });
    const [selectedFile, setSelectedFile] = useState(null);

    const [buttonStatus, setButtonStatus] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const fileInputRef = useRef();

    function formationAMFLabel (){
        return (

            <span className="amf-field">
                Parmi les critères de <a href="https://lautorite.qc.ca/devenir-professionnel/assurance-de-dommages/formation-minimale" target="_blank" rel="noreferrer">
                formation minimales de l'AMF
                </a>, lequel correspond le mieux à ta situation :
            </span>
        )
    }

    useEffect(() => {
        let statusContact = [firstName.status, lastName.status, phone.status, origin.status,
            formationAMF.status, attachment.status
        ];
        let statusAP = [firstName.status, lastName.status, phone.status, origin.status,
            formationAMF.status, attachment.status
        ];
        if (tag !== "Agent de contact"){
            if (statusAP.includes("error") || statusAP.includes("")) {
                setButtonStatus(false);
            } else {
                setButtonStatus(true);
            }

        }else{
            if (statusContact.includes("error") || statusContact.includes("")) {
                setButtonStatus(false);
            } else {
                setButtonStatus(true);
            }
        }
        return () => {};
    }, [firstName, lastName, phone, origin, formationAMF, attachment ]);


    function submitForm() {

        if (buttonStatus) {

            setLoadingButton(true)
            const formData = new FormData();
    
            formData.append("first_name", firstName.value)
            formData.append("last_name", lastName.value)
            formData.append("email", email.value)
            formData.append("phone", phone.value)
            formData.append("origin", origin.value)
            // formData.append("school_status", schoolStatus.value)
            // formData.append("study", educationLevel.value)
            // formData.append("program", sphereOfStudy.value)
            // formData.append("number_of_session", parseInt(numberOfSessions.value))
            // formData.append("number_of_hour", parseInt(numberOfHours.value))
            formData.append("attachment", selectedFile)
            // formData.append("hour_to_call", callback.value)
            formData.append("formation_amf", formationAMF.value)
            formData.append("department", tag)

    
            ApiClient.sendCarriere(formData)
                .then((res) => {
                    if (res.status === 201) {
                        // Do something here
                        setLoadingButton(false)
                        setCareerModal(true)

                    }else{
                        setTimeout(() => {
                            setLoadingButton(false)
                        }, 2000);
                    }
                }).catch((e) => {
                    console.error(e);
                });

        }
    }

    return (
    
    <>
        <div id="carrieres-form" className={`modal-ab ${show === null ? "" : show ? "show" : "hide"}`}>
            <img className="cross" src="img/carrieres/form/cross.svg" onClick={handleClose}/>
            <div>
                <h2><span>Postuler : </span>{title}</h2>

                <div className="row">
                    <div className="col">
                        <InputTextField
                            label="Prénom"
                            type="text"
                            maxLength="64"
                            onChange={(e) => {
                                const value = e.target.value;
                                onChange(value, setFirstName, (value) => testInput(value, Regx.ALPHA))
                            }}
                            {...firstName}
                        />
                    </div>

                    <div className="col">
                        <InputTextField
                            label="Nom"
                            type="text"
                            maxLength="64"
                            onChange={(e) => {
                                const value = e.target.value;
                                onChange(value, setLastName, (value) => testInput(value, Regx.ALPHA))
                            }}
                            {...lastName}
                        />
                    </div>
                </div>
                <div className="row">
                    <InputTextField
                        label="Courriel"
                        type="text"
                        onChange={(e) => {
                            const value = e.target.value;
                            onChange(value, setEmail, email.isValid)
                        }}
                        {...email}
                    />
                </div>

                <div className="row">

                    <div className="col">
                        <InputTextField
                            label="Téléphone"
                            type="tel"
                            maxLength="10"
                            onChange={(e) => {
                                const value = e.target.value;
                                onChange(value, setPhone, phone.isValid)
                            }}
                            {...phone}
                        />
                    </div>

                    <div className="col">
                        <SelectField
                            onChange={(e) => {
                                e.target.value !== ""
                                ? setOrigin({
                                    ...origin,
                                    value: e.target.value,
                                    status: "success",
                                    })
                                : setOrigin({
                                    ...origin,
                                    value: e.target.value,
                                    status: "",
                                    });
                            }}
                            label="Comment nous connais-tu?"
                            options={optionsOrigin}
                            {...origin}
                            />  
                    </div>
                </div>

                <div className="row">
                    <SelectField
                        onChange={(e) => {
                            e.target.value !== ""
                            ? setFormationAMF({
                                ...formationAMF,
                                value: e.target.value,
                                status: "success",
                                })
                            : setFormationAMF({
                                ...formationAMF,
                                value: e.target.value,
                                status: "",
                                });
                        }}
                        // label="Parmi les critères de formation minimales de l'AMF, lequel correspond le mieux à ta situation :"
                        label={formationAMFLabel()}
                        options={tag !== "Agent de contact"? optionsFormationAMFConseiller: optionsFormationAMFContact}
                        {...formationAMF}
                    />
                </div>

                {
                    false ? <div className="row"> </div> : ""
                }

                <div
                    onClick={() => {
                        fileInputRef.current.click()
                    }}
                    className="row" style={{marginTop: 50, marginBottom: 50}}>
                    <FileField
                        {...attachment}
                        onChange={( (e) => {
                            setSelectedFile(e.target.files[0]);
                            setAttachment({
                                ...attachment,
                                status: (hasExtension(e.target.files[0].name, ['doc', 'docx', 'pdf']) ? FIELD_STATUS.SUCCESS : FIELD_STATUS.ERROR),
                                fileName: e.target.files[0].name,
                                value: e.target.files[0].name
                            });
                        })}
                        inputRef={fileInputRef}
                        value={selectedFile ? '' : undefined}
                        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/msword"
                        label="Téléverse ton CV (pdf/docx/doc)" />
                </div>

                <div className="send-zone">
                    <div className="button">
                        {loadingButton ? (
                            <LoadingButton/>
                        ) : (
                            <MainButton
                            name="ENVOYER"
                            handle={submitForm}
                            activateSecondClass={true}
                            secondClassName={buttonStatus ? "main-button-inversed" : "disabled-button"}
                            />
                        )}
                    </div>
                </div>
                
            </div>
        </div>
    </>
)}
