import { useEffect, useState, useContext } from "react";
import { MainButton } from "../Buttons/Buttons";
import { CarrieresForm } from "./Form";
import { ShareButton } from "./ShareButton";
import ModalCareer from "../Modal/modalCareer";
import {ModalContext} from "../../contexts/modalContext";


export function Fiche({
  handleBack,
  title,
  lieu,
  horaire,
  salaire,
  salairedescription,
  bonification,
  desc,
  tag,
  ficheid,
  subtitle
}) {

  const { careerModal } = useContext(ModalContext)

  const [state, setState] = useState({
    showModal: null,
    drawer: true,
  });

  useEffect(() => {
    return () => {
      const body = document.getElementsByTagName("body")[0];
      body.classList.remove("stop-scrolling");
    };
  }, []);

  return (
  <>
    {careerModal? <ModalCareer/>: <></> }

    <div id="carrieres-fiche">
      <CarrieresForm
        show={state.showModal}
        title={title}
        tag={tag}
        handleClose={() => {
          setState({
            ...state,
            showModal: false,
          });
          const body = document.getElementsByTagName("body")[0];
          body.classList.remove("stop-scrolling");
        }}
      />
      <section id="carrieres-fiche-title">
        <a
          onClick={(e) => {
            e.preventDefault();
            handleBack();
          }}
          className="link fade-in-underline title">
          <img
            src="img/carrieres/carousel/prev_arrow.png"
            alt="agp assurance fleche"
          />
          <span>Retour aux offres d'emploi</span>
        </a>
        <h2>{title}</h2>
        <h3 style={{paddingTop: "1em", fontSize: "1.4em"}}>{subtitle}</h3>
        <div>
          <MainButton
            activateSecondClass={true}
            secondClassName="main-button-inversed"
            handle={() => {
              const body = document.getElementsByTagName("body")[0];
              body.classList.add("stop-scrolling");

              setState({
                ...state,
                showModal: true,
              });
            }}
            name="POSTULER"
          />
          <ShareButton ficheid={ficheid} title={title} desc={desc}/>
        </div>
      </section>

      <section id="carrieres-fiche-description">
        <div className="carrieres-fiche-infos">
          <div>
            <h3>Lieu</h3>
            <p>{lieu}</p>
          </div>

          <div className="right-div">
            <h3>Horaire</h3>
            <p>{horaire}</p>
          </div>

          <div>
            <h3>Salaire</h3>
            <p>{salaire}</p>
            <span>{salairedescription}</span>
          </div>

          <div className="right-div">
            {bonification?
              <>
                <h3>Bonification</h3>
                <p>{bonification}</p>
              </>
            :
              <></>
            }
            
          </div>
        </div>
        <div className={`desc drawer ${!state.drawer ? "close" : "open"}`}>
          <h3>
            DESCRIPTION{" "}
            <img
              onClick={(e) => {
                e.preventDefault();
                setState({
                  ...state,
                  drawer: !state.drawer,
                });
              }}
              src="img/arrow.png"
            />
          </h3>
          <div className="drawer-content">{desc}</div>
        </div>
      </section>
    </div>
    

    </>
  );
}
