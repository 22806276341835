import React, {useState} from "react";
import {MainButton} from "../Buttons/Buttons";

const ModalConcours = ({email, message}) => {

  const [display, setDisplay] = useState("")

  return (

    <section className="modal-concours" style={{display: display}}>
        <div className="overlay"></div>
        <div className="modal-content">
          <div className="quit" >
            <h1 onClick={() => setDisplay("none")}>x</h1>
          </div>
        <div className="modal-title">
            <h2>Il semble que vous soyez déjà inscrit...</h2>
            <img src="img/complete_9.png" alt="logo agp assurance homme 9" />
        </div>

        <div className="modal-error-message">
            <p>L'adresse courriel {email} est déjà enregistré pour se concours. Une seule participation possible.</p>
        </div>

        <div className="modal-choice">
            <a className="yes" href={"/"}>
            <MainButton name={"RETOURNER À L'ACCUEIL"} />
            </a>
        </div>
        </div>
    </section>
  );
};

export default ModalConcours;
